<template>
    <div id="editAutoCalibrationTagPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editAutocalibrationTag"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="act_editAutoCalibrationTagSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z" id="Path-20-Copy" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z" id="Path-20" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("act_editAutoCalibrationTag") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="form-group row validated">
                                                    <div class="col-lg-6">
                                                        <label for="editAutoCalibrationTagForm_serialNumberInput">{{ $t("act_autoCalibrationTagSerialNumberLabel") }}</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-barcode"></i></span>
                                                            </div>
                                                            <input v-model="vSerialNumber" @input="$v.vSerialNumber.$touch()" type="text" @keyup="onChangeForm" class="form-control" id="editAutoCalibrationTagForm_serialNumberInput" :placeholder="$t('act_autoCalibrationTagEnterSerialNumber')" />
                                                            <div v-if="!$v.vSerialNumber.required" class="invalid-feedback">
                                                                {{ $t("error_fieldIsRequired") }}
                                                            </div>
                                                            <div v-else-if="!$v.vSerialNumber.minLen" class="invalid-feedback">
                                                                {{ $t("error_minLengthMsg", [$v.vSerialNumber.$params.minLen.min]) }}
                                                            </div>
                                                        </div>
                                                        <span class="form-text text-muted"> {{ $t("act_autoCalibrationTagSerialNumberExample") }}</span>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label for="editAutoCalibrationTagForm_macAddressInput">{{ $t("act_autoCalibrationTagMacAddressLabel") }} *</label>
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i class="la la-code"></i></span>
                                                            </div>
                                                            <input v-model="vMacAddress" @keyup="onChangeForm" @input="$v.vMacAddress.$touch()" type="text" class="form-control toUppercase" id="editAutoCalibrationTagForm_macAddressInput" :placeholder="$t('act_autoCalibrationTagEnterMacAddress')" />
                                                            <div class="input-group-append">
                                                                <button id="editAutoCalibrationTagSearchButton" @click="onSearchButton" type="button" class="btn btn-brand">
                                                                    <i class="la la-search" style="color: white;"></i>
                                                                    {{ $t("act_searchAutoCalibrationTagByLabel") }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div v-if="!$v.vMacAddress.required" class="invalid-feedback">
                                                            {{ $t("error_fieldIsRequired") }}
                                                        </div>
                                                        <div v-else-if="$v.vMacAddress.$error" class="invalid-feedback">
                                                            {{ $t("error_invalidMacAddress") }}
                                                        </div>
                                                        <span class="form-text text-muted">{{ $t("act_autoCalibrationTagMacAddressExample") }}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="batteryInstalDateDatePicker">{{ $t("tag_batteryInstalDateLabel") }} *</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i class="la la-calendar"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control" id="batteryInstalDateDatePicker" readonly="" :placeholder="$t('tag_selectInstalDate')" />
                                                    </div>
                                                    <span class="form-text text-muted">{{ $t("tag_batteryInstalDateExample") }}</span>
                                                </div>
                                                <!--begin::div form-group tagType-->
                                                <div class="form-group row form-group-last form-group-marginless">
                                                    <label class="col-lg-2 col-form-label">{{ $t("tag_tagTypeLabel") }} *</label>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div :class="tagTypeColClass" v-for="tagType in tagTypes" v-bind:key="tagType.name">
                                                                <label class="kt-option cursorPointer" :class="tagType.id === vTagTypeId ? 'tagBorderSelected' : 'tagBorderNotSelected'">
                                                                    <span class="kt-option__control">
                                                                        <span class="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold" checked="">
                                                                            <input v-on:change="onChangeForm" v-model="vTagTypeId" type="radio" :id="'editAcTag_' + tagType.name.toLowerCase() + 'RadioButton'" :name="tagType.name" :value="tagType.id" :checked="tagType.id === vTagTypeId" />
                                                                            <span></span>
                                                                        </span>
                                                                    </span>
                                                                    <span class="kt-option__label">
                                                                        <span class="kt-option__head tagModelOptionHead" :style="tagModelHeadStyle">
                                                                            <span class="kt-option__title" :class="tagType.id === vTagTypeId ? 'tagLabelSelected' : 'tagLabelNotSelected'">
                                                                                {{ tagType.label }}
                                                                                <span v-if="tagType.isBeaconOALBT" class="kt-font-danger kt-font-bold">{{ $t("act_warningDeprecatedBeacon") }}</span>
                                                                            </span>
                                                                            <span class="kt-option__focus">
                                                                                {{ tagType.ref }}
                                                                            </span>
                                                                        </span>
                                                                        <span class="kt-option__body tagModelOptionBody" :style="tagModelBodyStyle">
                                                                            <div class="modelDesc">{{ tagType.desc }}</div>
                                                                            <img :class="tagType.imgClass" :src="tagType.img" />
                                                                            <div v-if="isDeprecatedTagType(tagType.name)" class="invalidSelectedTagModel">
                                                                                {{ $t("act_warningDeprecatedTagType") }}
                                                                            </div>
                                                                        </span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--end::div form-group tagType-->
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button id="editAutoCalibrationTagUpdateButton" @click="onUpdateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || !isFormDataChanged">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button id="editAutoCalibrationTagCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to search autocalibration tag mac address by label -->
                    <app-searchtagmacaddrbylabelmodal></app-searchtagmacaddrbylabelmodal>
                    <!-- end::Modal -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, macAddress, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import SearchTagMacAddrByLabelModal from "../modals/searchtagmacaddrbylabelmodal.vue";

export default {
    data() {
        return {
            vSerialNumber: "",
            vMacAddress: "",
            vTagTypeId: "",
            siteId: this.$route.params.siteId,
            batteryInstallationDate: moment().toISOString(),
            autoCalibrationTagId: this.$route.params.autoCalibrationTagId,
            isFormDataChanged: false,
            tagLoaded: false,
            tagTypes: [],
            tagTypeColClass: "col-lg-3",
            tagModelHeadStyle: "",
            tagModelBodyStyle: "",
            resizeTimeoutId: null
        };
    },
    created: function() {
        console.log("Component(editAutoCalibrationTag)::created() - called");
        window.addEventListener("resize", this.onResize);
        this.getTagTypes({ siteId: this.siteId });
    },
    mounted: function() {
        console.log("Component(editAutoCalibrationTag)::mounted() - Init metronic layout");
        KTLayout.init();
        let self = this;

        // Init datepicker
        let selector = $("#batteryInstalDateDatePicker");
        commonVueHelper.initDatePickerTag(selector, "bottom left", moment().toDate());
        selector.on("change", e => {
            let oldInstallDate = self.batteryInstallationDate;
            self.batteryInstallationDate = commonVueHelper.getDatePickerISOString(selector);
            if (self.tagLoaded && self.batteryInstallationDate !== oldInstallDate) {
                self.onChangeForm(e);
            }
        });
    },
    beforeDestroy: function() {
        console.log("Component(editAutoCalibrationTag)::beforeDestroy() - called");
        if ($("#batteryInstalDateDatePicker").length !== 0) {
            $("#batteryInstalDateDatePicker").datepicker("destroy");
        }
    },
    destroyed: function() {
        console.log("Component(editAutoCalibrationTag)::destroyed() - called");
        window.removeEventListener("resize", this.onResize);
        this.resetTagTypesState();
        this.resetShippedTagsState();
        this.resetAutocalibrationTagsState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vSerialNumber: {
            required,
            minLen: minLength(4)
        },
        vMacAddress: {
            required,
            macAddress: macAddress()
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        autocalibrationTagTypeObjsByName: function(list) {
            console.log("Component(editAutoCalibrationTag)::watch(autocalibrationTagTypeObjsByName) - called with : ", list);
            if (list && Object.keys(list).length > 0) {
                const payload = {
                    siteId: this.siteId,
                    autoCalibrationTagId: this.autoCalibrationTagId
                };
                this.getAutoCalibrationTagById(payload);
            }
        },

        currentAutoCalibrationTag: function(autoCalibrationTag) {
            console.log("Component(editAutoCalibrationTag)::watch(currentAutoCalibrationTag) - called with : ", autoCalibrationTag);
            this.initAutoCalibrationTag(autoCalibrationTag);
        },

        currentShippedTag: function(tag) {
            console.log("Component(editAutoCalibrationTag)::wath(currentShippedTag) - called with : ", tag);
            if (tag && tag.macAddress) {
                this.vMacAddress = tag.macAddress.toUpperCase();
                let tagTypeBeacon = this.autocalibrationTagTypeObjsByName["BEACON"];
                if (tagTypeBeacon) {
                    this.vTagTypeId = tagTypeBeacon.id;
                }
                this.onChangeForm();
            }
        },

        user: function(user) {
            console.log("Component(editAutoCalibrationTag)::watch(user) called with : ", user);
            if (user) {
                // Translate tag models
                this.updateTagTypes();
                // Resize tag models spans
                this.onResize();

                this.initAutoCalibrationTag(this.currentAutoCalibrationTag);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSite", "currentAutoCalibrationTag", "autocalibrationTagTypeObjsByName", "currentShippedTag", "user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["resetTagTypesState", "updateAutoCalibrationTag", "getAutoCalibrationTagById", "getTagTypes", "resetShippedTagsState", "resetAutocalibrationTagsState"]),

        // Update tag types translation with current user language
        updateTagTypes() {
            // Saved selected model
            const tagTypeIdSaved = this.vTagTypeId;
            this.vTagTypeId = null;
            // Update models translation
            for (let i=0; i<this.tagTypes.length; i++) {
                this.tagTypes[i] = commonVueHelper.getTagTypeItem(this.tagTypes[i]);
            }
            // Force refresh tag models
            this.vTagTypeId = tagTypeIdSaved;
        },

        initAutoCalibrationTag(autoCalibrationTag) {
            if (autoCalibrationTag) {
                this.siteId = this.$route.params.siteId;
                this.autoCalibrationTagId = this.$route.params.autoCalibrationTagId;
                this.vSerialNumber = autoCalibrationTag.serialNumber;
                this.vMacAddress = autoCalibrationTag.macAddress;
                let currentTagTypeName = "";
                let currentTagTypeReference = "";
                if (autoCalibrationTag.tagType) {
                    this.vTagTypeId = autoCalibrationTag.tagType.id;
                    currentTagTypeName = autoCalibrationTag.tagType.name;
                    currentTagTypeReference = autoCalibrationTag.tagType.reference;
                }
                if (autoCalibrationTag.hasOwnProperty("batteryInstallationDate")) {
                    commonVueHelper.setDatePickerVal($("#batteryInstalDateDatePicker"), moment(autoCalibrationTag.batteryInstallationDate).toDate());
                } else {
                    commonVueHelper.setDatePickerVal($("#batteryInstalDateDatePicker"), moment().toDate());
                }
                // Init the list of selectable tag models
                if (this.autocalibrationTagTypeObjsByName && Object.keys(this.autocalibrationTagTypeObjsByName).length > 0) {
                    let mySelf = this;
                    mySelf.tagTypes = [];
                    mySelf.tagTypeColClass = "col-lg-3";
                    for (let key of Object.keys(this.autocalibrationTagTypeObjsByName)) {
                        if (mySelf.autocalibrationTagTypeObjsByName[key]) {
                            if (mySelf.autocalibrationTagTypeObjsByName[key].ref === "OAL-BT") {
                                if (currentTagTypeReference !== "OAL-BT") {
                                    continue;
                                }
                                mySelf.autocalibrationTagTypeObjsByName[key]["isBeaconOALBT"] = true;
                            }
                            if (mySelf.isDeprecatedTagType(key)) {
                                if (key === currentTagTypeName) {
                                    // These tag models are no longer available but are keeped for beacons already configured with them.
                                    mySelf.tagTypes.push(mySelf.autocalibrationTagTypeObjsByName[key]);
                                }
                            } else {
                                // Add the tag type to the selectable tag model for a new autocalibration beacon creation
                                mySelf.tagTypes.push(mySelf.autocalibrationTagTypeObjsByName[key]);
                            }
                        }
                    }
                    // Object.keys(this.autocalibrationTagTypeObjsByName).forEach(key => {
                    // });
                    // Set the number of column to display with class col-lg-n
                    mySelf.tagTypeColClass = "col-lg-" + Math.floor(12 / (mySelf.tagTypes.length === 1 ? 2 : mySelf.tagTypes.length));
                }

                this.tagLoaded = true;
                this.onResize();
            }
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(editAutoCalibrationTag)::onCancelButton() - called");
            this.$router.push({ name: "autocalibrationtags" });
        },

        onUpdateButton() {
            console.log("Component(editAutoCalibrationTag)::onUpdateButton() - called");
            const data = {
                siteId: this.siteId,
                autoCalibrationTagId: this.autoCalibrationTagId,
                batteryInstallationDate: this.batteryInstallationDate,
                serialNumber: this.vSerialNumber,
                macAddress: this.vMacAddress.toUpperCase(),
                tagTypeId: this.vTagTypeId
            };
            this.updateAutoCalibrationTag(data);
        },

        onChangeForm(event) {
            this.isFormDataChanged = true;
        },

        onSearchButton() {
            $("#searchTagMacAddrByLabelModal").modal("show");
        },

        /**
         * Function called by "resize" event listener
         */
        onResize(event) {
            console.log("Component(editAutoCalibrationTag)::onResize() - called", event);
            // No height style, this to render the default height of models style
            this.tagModelHeadStyle = "";
            this.tagModelBodyStyle = "";
            // Waiting all displayed before resize tag models span
            if (this.resizeTimeoutId) {
                clearTimeout(this.resizeTimeoutId);
            }
            this.resizeTimeoutId = setTimeout(this.resizeTagModels, 100);
        },

        /**
         * Resize the tag models with the same height.
         */
        resizeTagModels() {
            console.log("Component(editAutoCalibrationTag)::resizeTagModels() - called");
            // Get the max height of tag model head style and set all model head with this height
            let maxTagModelHeadHeight = 0;
            const selectorTagModelHead = $(".tagModelOptionHead");
            if (selectorTagModelHead && selectorTagModelHead.length > 0) {
                selectorTagModelHead.each(function() {
                    if ($(this) && $(this).height() > maxTagModelHeadHeight) {
                        maxTagModelHeadHeight = $(this).height();
                    }
                });
                if (maxTagModelHeadHeight) {
                    this.tagModelHeadStyle = "height: " + maxTagModelHeadHeight + "px";
                }
            }

            // Get the max height of tag model body and set all model body height with this height
            let maxTagModelBodyHeight = 0;
            const selectorTagModelBody = $(".tagModelOptionBody");
            if (selectorTagModelBody && selectorTagModelBody.length > 0) {
                selectorTagModelBody.each(function() {
                    if ($(this) && $(this).height() > maxTagModelBodyHeight) {
                        maxTagModelBodyHeight = $(this).height();
                    }
                });
                if (maxTagModelBodyHeight) {
                    this.tagModelBodyStyle = "height: " + maxTagModelBodyHeight + "px";
                }
            }
        },

        isDeprecatedTagType(tagType) {
            let isDeprecatedTagType = false;
            switch (tagType) {
                case "ASSET":
                case "CARD":
                case "UNIVERSAL":
                    isDeprecatedTagType = true;
                    break;
            }
            return isDeprecatedTagType;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "app-searchtagmacaddrbylabelmodal": SearchTagMacAddrByLabelModal
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditAutoCalibrationTag", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}

.invalidSelectedTagModel {
    margin-top: 0.25rem;
    padding-top: 1.3rem;
    font-size: 80%;
    color: #fd397a;
    margin-left: -32px;
}
</style>
